import { Button, Form, Input, Modal, Upload, message,  } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";

// import TextArea from "antd/es/input/TextArea";

import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/loadersSlice";
import {
  createProfile,
  updateProfile,
  deleteProfile,
} from "../../../apicalls/profiles";

// import { name } from "ejs";

function ProfileForm({ show, setshow, reloadData, profile }) {
  const formRef = useRef(null);

  const { TextArea } = Input;

  const { user } = useSelector((state) => state.users);
  const [imagefile, setimagefile] = useState();
  const [iloading, setiLoading] = useState(false);

  const dispatch = useDispatch();
  let response = null;
  
  const demouserid= "demoknowme@ascentknowme.com" || process.env.DEMOUSERNAME

  let isDemoAccount = profile?.email === demouserid

  // console.log("profile data ", JSON.stringify(profile));


  const onfinish = async (values) => {
    // console.log("image file : ", imagefile);
    try {
      dispatch(setLoading(true));

      if (profile) {
        //modify project data
        
        values._id = profile._id;
//        values.trialenddate =  Date.now() + 8 * 24 * 60 * 60 * 1000

        // Date.now() + 11 * 24 * 60 * 60 * 1000,

        if (!imagefile) {
          values.imageurl = profile.imageurl;          
        }
        else {
          values.imageurl = imagefile;
        }

       

        //  console.log("image file : ", values.imageurl);

        response = await updateProfile(values);

        if (response.success) {
          dispatch(setLoading(false));
          message.success(response.message);
          reloadData();
          setshow(false);
        } else {
          dispatch(setLoading(false));
          message.error(response.error);
        }
      } else {
        //create new project
        // owner will be the logged in user
        // console.log("In Create");

        values.owner = user._id;
        values.imageurl = imagefile;
        // project members first member will be loggedin User

        // values.members = [
        //   {
        //     user: user._id,
        //     role: "owner",
        //   },
        // ];

        response = await createProfile(values);

        if (response.success) {
          message.success(response.message);
          setshow(false);
          reloadData();
        } else {
          throw new Error(response.error);
        }
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      // console.log("Form Values :", values , "Error : ", error.message);
    }
    // console.log("Form Values :", values);
  };

  let defaultFileList =  [
    {
      uid: '-1',
      status:"done",
      url: profile?.imageurl,

    },]


  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange =  (info) => {
    
    // if (info.file.status === "uploading") {
    //   setiLoading(true);
    //   return;
    // }
    // if (info.file.status === "done") {
    //   //      Get this url from response in real world.
    //  await getBase64(info.file.originFileObj, (url) => {
    //     setiLoading(false);

    //    setimagefile(url);

    //     console.log("Info details: ", url + " setimage :", imagefile);
    //   });
    // }

    getBase64(info.file.originFileObj, (url) => {
      setiLoading(false);

      setimagefile(url);

      // console.log("Info details: ", url + " setimage :", imagefile);
    });



  };


// working with error
  // const handleChange = async (info) => {
  //   // if (info.file.status === "uploading") {
  //   //   setiLoading(true);
  //   //   return;
  //   // }
  //   // if (info.file.status === "done") {
  //   //   //      Get this url from response in real world.
  //   //   getBase64(info.file.originFileObj, (url) => {
  //   //     setiLoading(false);

  //   //     setimagefile(url);

  //   //     console.log("Info details: ", url + " setimage :", imagefile);
  //   //   });
  //   // }

  //  await getBase64(info.file.originFileObj, (url) => {
  //     setiLoading(false);

  //     setimagefile(url);

  //     // console.log("Info details: ", url + " setimage :", imagefile);
  //   });
  // };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {iloading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (



    <Modal
      title={profile ? "EDIT PROFILE" : "CREATE NEW PROFILE"}
      open={show}
      onCancel={() => setshow(false)}
      centered
      width={700}
      onOk={() => {
        isDemoAccount ? alert("Demo account can not be edited") : formRef.current.submit();
      }}
      // onOk={() => {
      //   formRef.current.submit();
      // }}

      okText="Save"

    >
      {/* <Form
        layout="vertical"
        ref={formRef}
        onFinish={onfinish}
        initialValues={project}
      >
        <Form.Item label="Project Name" name="name">
          <Input placeholder="Project Name"></Input>
        </Form.Item>

        <Form.Item label="Description" name="desc">
          <TextArea placeholder="Project Description" />
        </Form.Item>
      </Form> */}

      <Form
        layout="horizontal"
        ref={formRef}
        onFinish={onfinish}
        initialValues={profile}
        disabled={isDemoAccount}
      >
        <p className="p-2 text-red-500">{isDemoAccount ? "Demo Account can not be edited" : ""}</p>
        <p>{user?.roll}</p>
        <Form.Item
          // className="mt-5 w-[100%]"
          label= {user?.roll === "business" ? "Contact person first name" : "First Name"}
          name="firstName"
          rules={[
            {
              required: true,
              message: "Field can not be blank",
            },
          ]}
        >
          <Input
          // onChange={(e) => {
          //   setProfileVal({ ...profileval, firstName: e.target.value });
          // }}
          />
        </Form.Item>
        <Form.Item
          // className="mt-5"
          label={user?.roll === "business" ? "Contact person last name" : "Last Name"}
          name="lastName"
          rules={[
            {
              required: true,
              message: "Field can not be blank",
            },
          ]}
        >
          <Input
          // onChange={(e) => {
          //   setProfileVal({ ...profileval, lastName: e.target.value });
          // }}
          />
        </Form.Item>
        <Form.Item
          centered
          label="Profile Image (less than 2 mb)"
          name="imageurl"
          rules={[
            {
              required: true,
              message: "image can not be empty",
            },
          ]}
        >
          
          <Upload
            // disabled={imagefile !== null}
            centered
            name="avatar"
             defaultFileList={defaultFileList}
             listType="picture-circle"
            className="avatar-uploader"
            maxCount={1}
            // showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            <Button type="dashed">Profile Image</Button>
          </Upload>
        </Form.Item>

      {user?.roll !== "business" && <Form.Item 
          
          label="Designation"
          name="designation"
          rules={[
            {
              required: true,
              message: "Field can not be blank",
            },
          ]}
        >
          <Input
          />
        </Form.Item>
}
        <Form.Item
          // className="mt-5 w-[100%]"
          label="Company Name"
          name="companyName"
          rules={[
            {
              required: true,
              message: "Field can not be blank",
            },
          ]}
        >
          <Input
          />
        </Form.Item>

 {/* //Address      */}
        {user?.roll === "business" &&  <Form.Item
          // className="mt-5 w-[100%]"
          label="Company Address"
          name="address"
          
        >
          <TextArea
          />
        </Form.Item>}

        <Form.Item
          // className="mt-5 "
          label="Website"
          name="website"
        >
          <Input
          // onChange={(e) => {
          //   setProfileVal({ ...profileval, website: e.target.value });
          // }}
          />
        </Form.Item>

        <Form.Item
          // className="mt-5 "
          label="Email Id"
          name="email"
          rules={[
            {
              required: true,
              message: "Field can not be blank",
            },
          ]}
        >
          <Input
          // onChange={(e) => {
          //   setProfileVal({ ...profileval, email: e.target.value });
          // }}
          />
        </Form.Item>
        <Form.Item
          // className="mt-5 "
          label="Mobile No (with Country Code)"
          name="mobileno"
          rules={[
            {
              required: true,
              message: "Field can not be blank and check your country code",
              min: 11,
            },
          ]}
        >
          <Input
          // onChange={(e) => {
          //   setProfileVal({ ...profileval, mobileno: e.target.value });
          // }}
          />
        </Form.Item>

{/* //gstin */}
        {user?.roll === "business" &&  <Form.Item
          // className="mt-5 w-[100%]"
          label="GST Tin Number"
          name="gstin"
          
        >
          <Input
          />
        </Form.Item>}


{/* //pan */}
{user?.roll === "business" &&  <Form.Item
          // className="mt-5 w-[100%]"
          label="PAN/ Income Tax Number"
          name="pannumber"
          
        >
          <Input
          />
        </Form.Item>}


{/* //tan */}
{user?.roll === "business" &&  <Form.Item
          // className="mt-5 w-[100%]"
          label="TAN Registration Number"
          name="tannumber"
          
        >
          <Input
          />
        </Form.Item>}

{/* //cin */}
{user?.roll === "business" &&  <Form.Item
          // className="mt-5 w-[100%]"
          label="CIN/ Corporatae Identity Number"
          name="cinnumber"
          
        >
          <Input
          />
        </Form.Item>}




{/* //udyam */}
{user?.roll === "business" &&  <Form.Item
          // className="mt-5 w-[100%]"
          label="UDYAM Registration Number"
          name="udyam"
          
        >
          <Input
          />
        </Form.Item>}


{/* //fssai */}
{user?.roll === "business" &&  <Form.Item
          // className="mt-5 w-[100%]"
          label="FSSAI Registration Number"
          name="fssai"
          
        >
          <Input
          />
        </Form.Item>}


{/* Bank Details      */}
{user?.roll === "business" &&  <Form.Item
          // className="mt-5 w-[100%]"
          label="Bank Details"
          name="bankdetails"
          
        >
          <TextArea
          />
        </Form.Item>}

{/* //upiID */}
{user?.roll === "business" &&  <Form.Item
          // className="mt-5 w-[100%]"
          label="Payment Upi ID"
          name="upiid"
          
        >
          <Input
          />
        </Form.Item>}

{/* Services Details      */}
{user?.roll === "business" &&  <Form.Item
          // className="mt-5 w-[100%]"
          label="Services"
          name="services"
          
        >
          <TextArea
          />
        </Form.Item>}


{/* //Product Cateloge */}
{user?.roll === "business" &&  <Form.Item
          // className="mt-5 w-[100%]"
          label="Product Catelog url"
          name="catelogurl"
          
        >
          <Input
          />
        </Form.Item>}



        <Form.Item label="Facebook link" name="facebook">
          <Input />
        </Form.Item>
        <Form.Item label="Instagram link" name="instagram">
          <Input />
        </Form.Item>
        <Form.Item label="Linkedin link" name="linkedin">
          <Input />
        </Form.Item>
        <Form.Item label="X link (Twitter)" name="socialx">
          <Input />
        </Form.Item>
        <Form.Item label="Google Map link" name="gmap">
          <Input />
        </Form.Item>

        
        <Form.Item label="Youtube link" name="youtube">
          <Input />
        </Form.Item>

        {user?.roll !== "business" &&
        <Form.Item label="Snapchat link" name="snapchat">
          <Input />
        </Form.Item>}

        {user?.roll !== "business" &&
        <Form.Item label="Tiktok link" name="tiktok">
          <Input />
        </Form.Item>}

        {user?.roll !== "business" &&
        <Form.Item label="WeChat link" name="wechat">
          <Input />
        </Form.Item>}



      </Form>
    </Modal>
  );
}

export default ProfileForm;
